import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Terms from "./style";
export default function Impressum() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">Impressum</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.TitleSmall as="h5">
                Angaben gem&auml;&szlig; &sect; 5 TMG
              </Terms.TitleSmall>
              <Terms.Text>
                Damien Servais <br />
                Einzelunternehmer
                <br />
                Lehmbruckstraße 15
                <br />
                10245 Berlin
              </Terms.Text>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Kontakt</Terms.TitleSmall>
                <Terms.Text>E-Mail: contact@myfeedoo.de</Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Urheberrecht</Terms.TitleSmall>
                <Terms.Text>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                  diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                  Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art
                  der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
                  bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen
                  Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
                  nur f&uuml;r den privaten, nicht kommerziellen Gebrauch
                  gestattet. <br />
                  Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                  erstellt wurden, werden die Urheberrechte Dritter beachtet.
                  Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                  Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                  aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
                  Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                  Inhalte umgehend entfernen. Quelle:{" "}
                  <a href="https://www.e-recht24.de">
                    https://www.e-recht24.de
                  </a>
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  );
}
